<template>
  <section class="welcome fullpage" id="welcome_section">
    <div class="welcome-container">
      <div class="welcome-title">Alejandro de <br />los Santos</div>
      <br />
      <div class="welcome-subtitle">
        <span class="subtitle-1">fullstack developer</span>
        <span class="subtitle-2">
          <span class="st">|</span>
          games programmer
        </span>
        <span class="subtitle-3">
          <span class="st">|</span>
          engineer
        </span>
      </div>
    </div>
    <div class="down-arrow" v-on:click="this.emitter.emit('scrollToSection', 1)">
      <i class="fas fa-angle-down"></i>
    </div>
  </section>
</template>

<script>
export default {
  name: "Navbar",
  mounted() {
    const el = document.querySelector("#welcome_section");

    var movementStrength = 25;
    var height = movementStrength / el.clientHeight
    var width = movementStrength / el.clientWidth

    el.addEventListener("mousemove", (e) => {
      var pageX = e.pageX - (el.clientWidth / 2);
      var pageY = e.pageY - (el.clientHeight / 2);
      var newvalueX = width * pageX * -1 - 25;
      var newvalueY = height * pageY * -1 - 50;
      
      el.style.backgroundPositionX = newvalueX + "px";
      el.style.backgroundPositionY = newvalueY + "px";
    });
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/app.scss";

.welcome {
  //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='921' height='110.5' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23222' stroke-width='3.9' %3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='400' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23404' stroke-width='1'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23505'%3E%3Ccircle cx='769' cy='229' r='5'/%3E%3Ccircle cx='539' cy='269' r='5'/%3E%3Ccircle cx='603' cy='493' r='5'/%3E%3Ccircle cx='731' cy='737' r='5'/%3E%3Ccircle cx='520' cy='660' r='5'/%3E%3Ccircle cx='309' cy='538' r='5'/%3E%3Ccircle cx='295' cy='764' r='5'/%3E%3Ccircle cx='40' cy='599' r='5'/%3E%3Ccircle cx='102' cy='382' r='5'/%3E%3Ccircle cx='127' cy='80' r='5'/%3E%3Ccircle cx='370' cy='105' r='5'/%3E%3Ccircle cx='578' cy='42' r='5'/%3E%3Ccircle cx='237' cy='261' r='5'/%3E%3Ccircle cx='390' cy='382' r='5'/%3E%3C/g%3E%3C/svg%3E");
  margin: auto;
  text-align: center;
  position: relative;
}

.welcome-container {
  position: absolute;
  top: 30%;
  width: 100%;
}

.welcome-title {
  font-size: 5rem;
  color: $color-white;
  text-shadow: 3px 3px 0px $color-blue-accent;
}

.welcome-subtitle {
  justify-content: center;
  display: flex;
  font-size: 2rem;
  width: 100%;
}

.down-arrow {
  position: absolute;
  bottom: 10%;
  font-size: 2rem;
  width: 100%;
  cursor: pointer;
  transition: 0.3s;
  z-index: 3;
}

.down-arrow:hover {
  color: $color-blue-accent;
  text-shadow: 3px 3px 0px $color-blue-accent;
  -webkit-transform: translateY(-5px);
}

.st {
  color: $color-blue-accent;
  margin-left: 0.7rem;
  margin-right: 0.7rem;
}
.subtitle-1 {
  animation: 1s ease-out 0s 1 loadin;
}
.subtitle-2 {
  animation: 2s ease-out 0s 1 loadin;
}
.subtitle-3 {
  animation: 3s ease-out 0s 1 loadin;
}
@keyframes loadin {
  0% {
    opacity: 0%;
  }
  50% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

@media only screen and (max-width: 750px) {
  .welcome-title {
    font-size: 3rem;
  }
  .welcome-subtitle {
    flex-direction: column;
    top: 60%;
    font-size: 1.5rem;
  }
  .st {
    visibility: hidden;
    margin: 0;
  }
}
</style>
